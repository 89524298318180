import React from 'react';
import "./SearchInput.css";
import Button, {ButtonSize, ButtonStyle} from "../Button/Button";

interface SearchInputProps {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
    id: string;
    onSearch: () => void;
}

const SearchInput = (props: SearchInputProps) => {

    const onSearch = () => {
        props.onSearch();
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            onSearch();
        }
    }

    return (
        <div className={'search-wrapper'}>
            {/*<TextInput size={TextInputSize.Middle} label={props.label} id={props.id} editable value={props.value} onChange={props.onChange}/>*/}
            <input placeholder={props.placeholder} className={'search-input'} onKeyDown={handleKeyDown} type={"text"}
                   value={props.value} onChange={(e) => props.onChange(e.target.value)}/>
            {/*<Button onClick={onSearch} size={ButtonSize.Middle} style={ButtonStyle.Primary}>Найти</Button>*/}
            <svg className={"search-icon"} focusable="false" height="24px" viewBox="0 0 24 24" width="34px" xmlns="http://www.w3.org/2000/svg" onClick={onSearch}>
                <path fill="#999999"
                    d="M20.49,19l-5.73-5.73C15.53,12.2,16,10.91,16,9.5C16,5.91,13.09,3,9.5,3S3,5.91,3,9.5C3,13.09,5.91,16,9.5,16 c1.41,0,2.7-0.47,3.77-1.24L19,20.49L20.49,19z M5,9.5C5,7.01,7.01,5,9.5,5S14,7.01,14,9.5S11.99,14,9.5,14S5,11.99,5,9.5z"></path>
                <path d="M0,0h24v24H0V0z" fill="none"></path>
            </svg>
        </div>

        // <div className={'search-wrapper'}>
        //     <TextInput size={TextInputSize.Small} label={props.label} id={props.id} editable value={props.value} onChange={props.onChange}/>
        //     <Button onClick={onSearch} size={ButtonSize.Middle} style={ButtonStyle.Primary}>Найти</Button>
        // </div>
    );
};

export default SearchInput;
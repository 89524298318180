import {RootStore} from "./RootStore";
import {makeAutoObservable} from "mobx";
import {AUTH_URL, CLIENT_ID, ROOT_HOST, SCOPE} from "../constants";
import {RequestState} from "../types/RequestState";
import {User, UserManager, UserManagerSettings} from "oidc-client";

export class AuthStore {
    user: User | null = null;
    public readonly manager: UserManager;
    private rootStore: RootStore;
    public signinSilentRequestState: RequestState = RequestState.Done;

    constructor(rootStore: RootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        let config: UserManagerSettings = {
            authority: AUTH_URL,
            client_id: CLIENT_ID,
            redirect_uri: `${ROOT_HOST}/authentication/login-callback`,
            silent_redirect_uri: `${ROOT_HOST}/silent-callback.html`,
            post_logout_redirect_uri: `${ROOT_HOST}`,
            response_type: "code",
            revokeAccessTokenOnSignout: true,
            scope: SCOPE,
            includeIdTokenInSilentRenew: true,
            automaticSilentRenew: true,
        };
        this.manager = new UserManager(config);
        this.getUser();
    }

    getUser = () => {
        this.manager
            .getUser()
            .then((user) => {
                if (user) {
                    this.user = user;
                    console.log("get user", user);
                }

            })
            .catch((error) => {
                console.log(error);
            });
    };

    get isAuthenticated(): boolean {
        return this.user != null && !this.user.expired;
    }

    signinSilentCallback = () => {
        this.manager
            .signinSilentCallback()
            .then((user) => {
                if (user) this.user = user;
                window.history.replaceState(
                    {},
                    window.document.title,
                    window.location.origin
                );
            })
            .catch((error) => {
            });
    };

    signinRedirectCallback = () => {
        return this.manager
            .signinRedirectCallback()
            .then((user) => {
                this.user = user;

                let needReLogin = localStorage.getItem("needReLogin");
                if (needReLogin == "true") {
                    // localStorage.setItem("needReLogin", "false");
                    console.log("sign out ", user.profile.sub);
                    this.manager.signoutRedirect(
                        {
                            id_token_hint: this.user.id_token,
                            post_logout_redirect_uri: ROOT_HOST + "/authentication/login",
                            state: this.user.session_state
                        }
                    );
                }
            })
            .catch((error) => {
                console.log("signinRedirectCallback =" + error);
            });
    };

    signinRedirect = (key: string | null) => {
        // console.log("USER", this.user);
        // console.log("key", key);
        // console.log("needReLogin", localStorage.getItem("needReLogin"));
        // console.log("======================================");
        if (key != null) {
            localStorage.setItem("key", key);
            if (this.user != null) {
                // localStorage.setItem("needReLogin", "false");
                this.manager.signoutRedirect(
                    {
                        id_token_hint: this.user.id_token,
                        post_logout_redirect_uri: ROOT_HOST + "/authentication/login",
                        state: this.user.session_state
                    }
                );
            } else {
                if(localStorage.getItem("needReLogin") == "true") {
                    localStorage.setItem("needReLogin", "false");
                } else {
                    localStorage.setItem("needReLogin", "true");
                }
                this.manager
                    .signinRedirect({
                        extraQueryParams: {
                            client_id: CLIENT_ID,
                            emailConfirmRedirect: ROOT_HOST + "/confirm-email",
                            key: key
                        },
                    })
                    .then(() => {
                    })
                    .catch((error) => {
                    });
            }
        } else {
            this.manager
                .signinRedirect({
                    extraQueryParams: {
                        client_id: CLIENT_ID,
                        emailConfirmRedirect: ROOT_HOST + "/confirm-email",
                        key: key
                    },
                })
                .then(() => {
                })
                .catch((error) => {
                });
        }

    };

    signinSilent = () => {
        this.signinSilentRequestState = RequestState.Pending;
        this.manager
            .signinSilent()
            .then((user) => {
                this.user = user;
                console.log("get user signinSilent", user);
                this.signinSilentRequestState = RequestState.Done;
            })
            .catch((error) => {
                this.signinSilentRequestState = RequestState.Error;
            });
    };

    stopSilentRenew = () => {
        this.manager.stopSilentRenew();
    };

    logout = () => {
        localStorage.removeItem("key");
        this.manager
            .signoutRedirect()
            .then((_) => {
            })
            .catch((error) => {
            });
    };
}

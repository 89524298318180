import React from "react";
import "./NlmkOfferModalWindow.css";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import ModalWindow from "../ModalWindow/ModalWindow";
import {RequestState} from "../../types/RequestState";
import NlmkOfferBody from "./NlmkOfferBody";

interface NlmkModalModalWindowProps {
    onClose: () => void;
    onAccept: () => void;
    acceptOfferRequestState: RequestState;
}

const NlmkModalModalWindow = (
    props: NlmkModalModalWindowProps
) => {
    return (
        <ModalWindow onClickOutside={props.onClose} contentClassName={"nlmk-accept-offer-modal-content"}>
            <div className={"nlmk-offer-modal-window"}>
                <div className={"nlmk-offer-modal-window__body"}>
                    <NlmkOfferBody/>
                </div>
                <div className={"nlmk-offer-modal-window__footer"}>
                    <Button
                        onClick={props.onClose}
                        size={ButtonSize.Big}
                        style={ButtonStyle.Primary}
                        type={[ButtonType.Transparent]}
                    >
                        Закрыть
                    </Button>

                    <Button
                        onClick={props.onAccept}
                        size={ButtonSize.Big}
                        style={ButtonStyle.Primary}
                        pending={props.acceptOfferRequestState == RequestState.Pending}
                    >
                        Принять
                    </Button>
                </div>
                {/*<NlmkOfferBody/>*/}

            </div>
        </ModalWindow>
    );
};

export default NlmkModalModalWindow;

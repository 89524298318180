import classNames from "classnames";
import React, {FC, ReactNode, useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import "./Tooltip.css";

type ITooltipStyle = {
  left?: string;
  top?: string;
  bottom?: string;
  right?: string;
};

type TooltipProps = {
  children: ReactNode;
  content: string;
  position?: "top" | "left" | "right" | "bottom";
  align?: "left" | "center" | "right"
};

const Tooltip: FC<TooltipProps> = (props) => {
  const {children, content, position = "top", align = "center"} = props;

  const [visible, setVisible] = useState<boolean>(false);
  const togglerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  let style: ITooltipStyle = {};
  if (togglerRef.current && togglerRef.current.offsetWidth) {
    const size = togglerRef.current.getBoundingClientRect();

    // console.log('aaa',togglerRef.current, size.left, size, window.innerWidth, size.left / window.innerWidth);
    if (position === "top" || position === "bottom") {
      if (size.left < 50) {
        style.left = `120px`;
      } else if ((size.left / window.innerWidth) > 0.6) {
        style.left = `-0px`;
      } else {
        style.left = `${String(togglerRef.current.offsetWidth / 2)}px`;
      }

    } else {
      style.top = `-${String(togglerRef.current.offsetHeight / 1.5)}px`;
    }
  }

  return (
    <div className="tooltip">
      <div
        className="tooltip__toggler"
        onMouseEnter={show}
        onMouseLeave={hide}
        ref={togglerRef}>
        {children}
      </div>
      <CSSTransition
        in={visible}
        timeout={500}
        classNames="tooltip"
        unmountOnExit>
        <div
          className={classNames(
            "tooltip__content",
            `tooltip__content-${position}`,
            `tooltip__content-align-${align}`
          )}
          ref={contentRef}
          style={style}>
          {content}
        </div>
      </CSSTransition>
    </div>
  );
};

export default Tooltip;
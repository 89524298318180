import React from "react";
import "./UserBar.css";
import {NavLink} from "react-router-dom";
import {CSSTransition} from "react-transition-group";
import Arrow, {ArrowType} from "../Arrow/Arrow";
import Point, {PointSize} from "../Point/Point";
import {useClickOutside} from "../../hooks/useClickOutside";
import {getFormattedUserName} from "../../utils";
import Switch, {Case, Default} from "react-switch-case";
import {CLIENT_ID} from "../../constants";

interface UserBarProps {
    balance: number;
    name?: string;
}

const UserBar = (props: UserBarProps) => {
    const [wrapperRef, isOpen, setIsOpen] = useClickOutside(false);
    return (
        <div className={"user-bar"} ref={wrapperRef}>
            <div
                className={"user-bar__body"}
                onClick={() => {
                    if (window.matchMedia('(min-width: 786px)').matches) {
                        setIsOpen(!isOpen);
                    }
                }}
            >
                <div className={"user-bar__name"}>
                    <div>{getFormattedUserName(props.name)}</div>
                    <div className={"user-bar__submenu--mob-hide"}>
                        <Arrow type={isOpen ? ArrowType.Up : ArrowType.Down}/>
                    </div>
                </div>
                <div className={"user-bar__balance"}><Point amount={props.balance} size={CLIENT_ID == "nlmk-catalog-webui" ? PointSize.Big : PointSize.Normal}/></div>
            </div>
            <CSSTransition
                in={isOpen}
                unmountOnExit
                classNames="submenu"
                timeout={200}
            >
                <div className={"user-bar__submenu"}>
                    <div className={"user-bar__submenu-item"}>
                        <NavLink className={"user-bar__submenu-link"} to={"/events"}>
                            <Switch condition={CLIENT_ID}>
                                <Case value={"nlmk-catalog-webui"}>
                                    Заказы
                                </Case>
                                <Default>
                                    События
                                </Default>
                            </Switch>
                        </NavLink>
                    </div>
                    <div className={"user-bar__submenu-item"}>
                        <NavLink className={"user-bar__submenu-link"} to={"/profile"}>
                            Настройки профиля
                        </NavLink>
                    </div>
                    <div className={"user-bar__submenu-item"}>
                        <NavLink className={"user-bar__submenu-link"} to={"/authentication/logout"}>
                            Выйти
                        </NavLink>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
};

export default UserBar;

import {GiftCertificateModel} from "../types/GiftCertificateModel";
import {ProductDetailModel} from "../types/ProductDetailModel";
import {httpGet, httpPostJson} from "../helpers/http";
import {BannerModel} from "../types/BannerModel";
import {
  GetRestGiftCertificatesNumbersHttpRequest,
  RestGiftCertificatesNumberDto
} from "../types/RestGiftCertificatesNumberDto";

export const getProductDetail = (id: string): Promise<ProductDetailModel> => {
  return httpGet("/api/v1/products/" + id).then((response) => {
    return response;
  });
};

export const getGiftCertificates = (search?: string): Promise<GiftCertificateModel[]> => {
  let url = "/api/v1/catalogs/gift-certificates";
    if (search) {
        url += "?q=" + search;
    }
  return httpGet(url).then((response) => {
    return response;
  });
};

export const getBanners = (): Promise<BannerModel[]> => {
  return httpGet("/api/v1/catalogs/banners").then((response) => {
    return response;
  });
};


export const getGetRestGiftCertificatesNumbers = (request: GetRestGiftCertificatesNumbersHttpRequest): Promise<RestGiftCertificatesNumberDto[]> => {

  return httpPostJson("/api/v1/catalogs/gift-certificates/rest-numbers", JSON.stringify(request)).then((response) => {
    return response;
  });
};


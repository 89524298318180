import React, {ReactNode} from "react";
import "./FormRow.css";

interface FormRowProps {
  children: ReactNode;
  hint?: string
}

const FormRow = (props: FormRowProps) => {
  return <div className={"form-row"}>
    <div className={"form-row-item"}>
      {props.children}
    </div>
    {props.hint && <div className="activate-code-hint">* {props.hint}</div>}
  </div>
};

export default FormRow;

import React, {useEffect} from "react";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useStores} from "../store";
import {useMediaQuery} from "react-responsive";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AuthenticationPage = () => {
  const { action} = useParams<{ action: string, key?: string }>();
  const { store } = useStores();
  let history = useHistory();
  let query = useQuery();

  useEffect(() => {
    if (action === "login") {
      let key = query.get("key") ||  localStorage.getItem("key");
      store.auth.signinRedirect(key);
    }
    if (action === "login-callback") {
      store.auth.signinRedirectCallback().then(() => {
        const redirect = localStorage.getItem("redirect")
        store.participants.getProfile()
        store.participants.getBalance();
        store.catalog.getGiftCertificates();
        store.catalog.getBanners();

        if(redirect){
          history.push(redirect);
        }else{
          history.push("/");
        }
      });
    }
    if (action === "logout") {
      store.auth.logout();
    }
  }, [action, history, store.auth]);

  return (
    <div>
      {/*<div>{action}</div>*/}
      {/*{action === "login" && <div>login</div>}*/}
      {/*{action === "login-callback" && <div>login-callback</div>}*/}
    </div>
  );
};

export default AuthenticationPage;

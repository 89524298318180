import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import "./ProductListItem.css";
import img from "../../assets/images/Decanter-card.png";

interface ProductListItemProps {
    to: string;
    imagePath: string;
}

const ProductListItem = (props: ProductListItemProps) => {
    // const [isVisible, setIsVisible] = useState(false);
    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsVisible(true)
    //     },Math.floor(Math.random() * 1500))
    // }, []);
    return (
        // <>{isVisible && <div className={"product-list-item"}>
        //   <NavLink to={props.to}>
        //     <img className={"product-list-item__image"} src={props.imagePath} alt={"notFound"} />
        //   </NavLink>
        // </div>}</>

        <div className={"product-list-item"}>
            <NavLink to={props.to}>
                <img className={"product-list-item__image"} src={props.imagePath} alt={"notFound"}/>
            </NavLink>
        </div>
    );
};

export default ProductListItem;

import React from 'react';

const html = `
<div>
      <p
        style="line-height: normal; font-size: 12pt; background-color: #ffffff"
      >
        <span style="font-weight: bold">Оферта</span>
      </p>
      <p style="margin-bottom: 14pt; line-height: normal; font-size: 12pt">
        <span>Настоящий документ определяет порядок использования сайта</span
        ><span>&#xa0;</span><span>https://nlmk.vektor-adv.ru</span
        ><span>&#xa0;</span
        ><span
          >и является публичной офертой ООО «Вектор» (далее — Исполнитель) в
          соответствии со ст. 437 ГК РФ. Оферта адресована любому физическому
          лицу (далее — Пользователь) на изложенных ниже условиях.</span
        >
      </p>
      <p style="line-height: normal; font-size: 12pt">
        <br /><span style="font-weight: bold">Термины и определения:</span>
      </p>
      <table
        cellspacing="0"
        cellpadding="0"
        style="width: 100%; margin-bottom: 0pt; border-collapse: collapse"
      >
        <tr>
          <td
            style="
              width: 76.05pt;
              padding-top: 3.75pt;
              padding-right: 15pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">Сайт</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span>веб-сайт</span><span>&#xa0;</span
              ><span>https://nlmk.vektor-adv.ru</span><span>&#xa0;</span
              ><span
                >(включая поддомены), который принадлежит Исполнителю и содержит
                ассортимент позиций для оформления Заказа.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 76.05pt;
              padding-top: 3.75pt;
              padding-right: 15pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">Пользователь</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span>физическое лицо, использующее Сайт.</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 76.05pt;
              padding-top: 3.75pt;
              padding-right: 15pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">Заказ</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span
                >запрос Пользователя на приобретение отдельных позиций из
                ассортимента на Сайте.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 76.05pt;
              padding-top: 3.75pt;
              padding-right: 15pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">Личный кабинет</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span
                >страница Пользователя на Сайте, в которой отражается информация
                о Заказах и балансе электронных баллов (Ферумов).</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 76.05pt;
              padding-top: 3.75pt;
              padding-right: 15pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">Держатель</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span
                >физическое лицо, которое получило Сертификат и является
                конечным пользователем.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 76.05pt;
              padding-top: 3.75pt;
              padding-right: 15pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">Эмитент</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span
                >юридическое лицо или индивидуальный предприниматель, который
                выпустил Сертификат и взял на себя обязательство в будущем
                предоставить Держателю товары (работы, услуги), имущественные
                права в соответствии с условиями, доступными на сайте
                Эмитента.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 76.05pt;
              padding-top: 3.75pt;
              padding-right: 15pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">Сертификат</span>
            </p>
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold; -aw-import: ignore">&#xa0;</span>
            </p>
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">Ферумы</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span
                >электронный сертификат, который подтверждает право требования
                Держателя к Эмитенту на получение товаров (работ, услуг) на
                сумму, равную номинальной стоимости Сертификата.</span
              >
            </p>
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span
                >электронные баллы (корпоративная валюта) программы признания
                НЛМК Сферум, используемые для оплаты заказа подарочных
                сертификатов на сайте nlmk.vektor-adv.ru</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              width: 76.05pt;
              padding-top: 3.75pt;
              padding-right: 15pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">Акцепт</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-top: 11.25pt; margin-bottom: 0pt; font-size: 12pt">
              <span
                >полное и безоговорочное принятие положений Оферты
                Пользователем.</span
              ><br /><span>Акцептом признается:</span><br /><span
                >- регистрация;</span
              ><br /><span
                >- оформление Заказа с последующей его оплатой Ферумами.</span
              >
            </p>
          </td>
        </tr>
      </table>
      <p style="line-height: normal; font-size: 12pt">
        <span style="display: none; -aw-import: ignore">&#xa0;</span>
      </p>
      <table
        cellspacing="0"
        cellpadding="0"
        style="width: 100%; margin-bottom: 0pt; border-collapse: collapse"
      >
        <tr>
          <td
            colspan="3"
            style="
              padding-top: 7.5pt;
              padding-bottom: 7.5pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold; -aw-import: ignore">&#xa0;</span>
            </p>
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold; -aw-import: ignore">&#xa0;</span>
            </p>
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold; -aw-import: ignore">&#xa0;</span>
            </p>
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold; -aw-import: ignore">&#xa0;</span>
            </p>
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold">1. Предмет</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">1.1.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Исполнитель обязуется по заказу Пользователя (физического лица)
                и за его счет в Ферумах приобрести и отправить на его
                электронную почту Сертификаты отдельных эмитентов, а
                Пользователь (физическое лицо) обязуется в момент создания
                заказа принять и оплатить их Ферумами.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            style="
              padding-top: 7.5pt;
              padding-bottom: 7.5pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold">2. Авторизация</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">2.1.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Пользователь автоматически авторизуется на сайте при переходе
                по ссылке из Личного кабинета программы признания НЛМК
                Сферум.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">2.2.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Пользователь гарантирует, что при заполнении форм на Сайте он
                вводит достоверные данные.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">2.3.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span>Пользователь имеет возможность:</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">2.3.1.</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span>Оплачивать Сертификаты Ферумами;</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">2.3.2.</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Отслеживать историю Заказов, транзакций, использовать другие
                возможности Личного кабинета.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            style="
              padding-top: 7.5pt;
              padding-bottom: 7.5pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold"
                >3. Порядок оформления Заказа</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">3.1.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Пользователь вправе оформить Заказ в Личном кабинете
                Сайта.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">3.2.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span>Порядок оформления Заказа в Личном кабинете Сайта:</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">3.2.1.</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Пользователь выбирает необходимый Сертификат, наименование,
                количество и цена которого отражаются на страницах "Каталог" и
                "Корзина";</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">3.2.2.</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span>После этого Пользователь нажимает кнопку "Заказать".</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">3.3.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Нажатием кнопки "Заказать" Пользователь подтверждает, что
                ознакомлен с информацией об условиях использования Сертификата,
                об адресе (месте нахождения) Эмитента, о сроке, в течение
                которого действует предложение о заключении договора с Эмитентом
                и т.п.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">3.4.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Все сведения о Сертификате на Сайте носят справочный характер и
                не могут в полной мере передавать полную информацию о таком
                Сертификате.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">3.5.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >После уведомления Исполнителя о невозможности выполнить Заказ
                или его часть, Пользователь вправе:</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">3.5.1.</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span>полностью отменить Заказ;</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">3.5.2.</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span>согласиться на частичное исполнение Заказа;</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">3.5.3.</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span>согласиться на замену Сертификата.</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            style="
              padding-top: 7.5pt;
              padding-bottom: 7.5pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold">4. Порядок оплаты Заказа</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">4.1.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Цена Подарочного сертификата указана на Сайте и может быть
                изменена Эмитентом в одностороннем порядке по согласованию с
                Исполнителем.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">4.2.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Общая стоимость Заказа складывается из суммарной стоимости всех
                выбранных Сертификатов.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">4.3.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >С момента оформления Заказа Пользователем цена на Сертификат в
                Заказе изменению не подлежит.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">4.4.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Пользователь оплачивает Заказ Ферумами, где 1 (один) Ферум
                равен 100 (ста) рублям.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            style="
              padding-top: 7.5pt;
              padding-bottom: 7.5pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">4.5.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Особенности оплаты. В соответствии с Федеральным законом от
                27.06.2011 № 161-ФЗ (ред. от 03.07.2016) «О национальной
                платежной системе» Исполнитель не является оператором по
                переводу денежных средств, оператором электронных денежных
                средств, банковским платежным агентом (субагентом), платежным
                агентом, оператором платежных систем, оператором услуг платежной
                инфраструктуры.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            style="
              padding-top: 7.5pt;
              padding-bottom: 7.5pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold"
                >5. Порядок исполнения Заказа</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">5.1.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Срок исполнения Заказа зависит от наличия Сертификатов у
                Исполнителя и времени, необходимого на обработку Заказа.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">5.2.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span>Максимальный срок исполнения Заказа Исполнителем:</span>
            </p>
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">5.2.1.</span
              ><span style="width: 7.54pt; display: inline-block">&#xa0;</span
              ><span>При наличии на складе – до 2 (двух) рабочих дней;</span>
            </p>
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">5.2.2.</span
              ><span style="width: 7.54pt; display: inline-block">&#xa0;</span
              ><span>При отсутствии на складе – до 5 (пяти) рабочих дней.</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">5.3.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >В исключительных случаях срок исполнения Заказа может быть
                оговорен с Исполнителем индивидуально и изменен в зависимости от
                характеристик и количества заказанных Сертификатов.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            style="
              padding-top: 7.5pt;
              padding-bottom: 7.5pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold">Доставка</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">5.4.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Заказ считается доставленным в момент отправки ссылки для
                скачивания Сертификата на адрес электронной почты, указанный
                Пользователем.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">5.5.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Доставка заказа осуществляется Пользователю по электронному
                адресу, указанному им при Регистрации.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">5.7.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Сертификат не является товаром/ценной бумагой и не подлежит
                возврату/обмену на денежные средства.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            style="
              padding-top: 7.5pt;
              padding-bottom: 7.5pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold"
                >6. Порядок использования Сертификата</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">6.1.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Исполнитель является агентом Эмитентов по реализации
                Сертификатов. Все обязанности по использованию Сертификатов
                возникают у Эмитентов.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">6.2.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Эмитенты самостоятельно обслуживают использование Сертификатов,
                приобретенных Пользователем, согласно правилам продажи товаров,
                работ или услуг, установленным Эмитентом (далее – Правила
                продажи). С Правилами продажи Пользователь обязуется
                ознакомиться самостоятельно на сайте Эмитента.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">6.3.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Ни при каких обстоятельствах Исполнитель не несет
                ответственности перед Пользователем в случае невозможности
                Эмитента обеспечить выполнение предъявленного им права
                требования по Сертификату, полученному от Исполнителя. В случае
                возникновения каких-либо претензий Пользователь обязан
                обращаться напрямую к Эмитенту.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            style="
              padding-top: 7.5pt;
              padding-bottom: 7.5pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold">7. Ответственность сторон</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">7.1.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Стороны несут ответственность за неисполнение или ненадлежащее
                исполнение обязательств, установленных Офертой, в соответствии с
                законодательством Российской Федерации.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">7.2.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span>Исполнитель не несет ответственности за:</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">7.2.1</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Ненадлежащее исполнение Оферты по доставке Сертификата в связи
                с предоставлением Пользователем недостоверных данных;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">7.2.2</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Нарушение правил или ненадлежащее использование приобретенных
                Сертификатов;</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">7.2.3</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Ущерб, нанесенный Пользователю либо третьим лицам
                вследствие:</span
              ><br /><span
                >- утери и/или разглашения данных для доступа к Личному кабинету
                (в магазине Эмитента) или информации о коде Сертификатов;</span
              ><br /><span
                >- несоблюдения Пользователем правил использования Сертификата,
                утвержденных Эмитентом.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">7.3.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Пользователь принимает на себя полную ответственность за:</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">7.3.1</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Недостоверность указанных в Заказе сведений об электронном
                адресе доставки.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">7.4.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Форс-мажор (непреодолимая сила) — любое действие, событие или
                обстоятельство, не являющееся результатом деятельности Сторон
                или находящееся вне контроля Сторон, включая технические
                проблемы с Сайтом, стихийное бедствие, пожар, военные действия,
                действия государственных органов и другие события, если в
                результате таких действий/событий затрудняется (становится
                невозможным) исполнение договорных обязательств. Сторона,
                оказавшаяся не в состоянии исполнить договорные обязательства,
                обязана незамедлительно письменно уведомить другую сторону о
                таких обстоятельствах и их влиянии на исполнение обязательств по
                Оферте. Факты, изложенные в уведомлении Стороны, должны быть
                подтверждены любым образом, прямо указывающим на наступление
                события Форс-мажор. Если данные обстоятельства продолжаются
                более 60 (шестидесяти) дней, Оферта может быть расторгнута
                другой Стороной с подачей письменного уведомления не выполняющей
                обязательства Стороне.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            style="
              padding-top: 7.5pt;
              padding-bottom: 7.5pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold">8. Урегулирование споров</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">8.1.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Все споры или разногласия, возникающие между Сторонами в связи
                с исполнением Оферты, разрешаются путем переговоров.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">8.2.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Обязательным является предъявление претензии, срок рассмотрения
                которой устанавливается в 10 (десять) рабочих дней с даты ее
                вручения другой стороне.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">8.2.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >В случае невозможности разрешения споров или разногласий путем
                переговоров, Пользователь вправе обратиться в НЛМК для
                дальнейшего урегулирования в суде по месту нахождения
                Исполнителя в порядке, установленном законодательством
                Российской Федерации.</span
              >
            </p>
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            style="
              padding-top: 7.5pt;
              padding-bottom: 7.5pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold"
                >9. Защита персональных данных</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">9.1.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Исполнитель выполняет обработку персональных данных
                Пользователя в целях исполнения Оферты и согласно требованиям,
                установленным Федеральным законом «О персональных данных» от
                27.07.2006 N 152-ФЗ.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">9.2.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Порядок обработки и защита персональных данных определяется
                Политикой в области обработки и защиты персональных данных,
                расположенной по </span
              ><a href="https://storage.yandexcloud.net/vector-public/offers/agreement.docx">ссылке</a><span>. </span>
            </p>
          </td>
        </tr>
        <tr>
          <td
            colspan="3"
            style="
              padding-top: 7.5pt;
              padding-bottom: 7.5pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; text-align: center; font-size: 12pt">
              <span style="font-weight: bold"
                >10. Заключительные положения</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">10.1.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Оферта вступает в силу с момента Акцепта и действует до момента
                удаления Личного кабинета.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">10.2.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >В случае необходимости коммуникации между Сторонами будут
                осуществляться:</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">10.2.1</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Со стороны Пользователя: по контактному номеру и адресу
                электронной почты, указанным в Личном кабинете.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="-aw-import: ignore">&#xa0;</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">10.2.2</span>
            </p>
          </td>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Со стороны Исполнителя: с любого адреса электронной почты
                доменной зоны Исполнителя, номеру телефона Исполнителя,
                указанному на Сайте.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">10.3.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Переписка по электронной почте или в Личном кабинете на Сайте
                имеет юридическую силу, в том числе в случае судебного
                разбирательства. Такой способ обмена документами и информацией
                является надлежащим.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">10.4.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Стороны обязуются извещать друг друга о любых изменениях своих
                контактных данных в течение 5 (Пяти) рабочих дней с момента их
                изменения. В противном случае, сообщения, переданные по
                последним известным электронному адресу или телефону считаются
                переданными надлежащим образом.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">10.5.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Исключительные права на все размещенные на Сайте объекты, в том
                числе базы данных, элементы дизайна, тексты, графические
                изображения, иллюстрации, фотографии, другие объекты и любой
                контент, принадлежат Исполнителю и другим правообладателям.
                Пользователи не вправе использовать контент Сайта целиком или по
                частям без предварительного разрешения правообладателей.</span
              >
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">10.6.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Исполнитель вправе в любое время вносить изменения в условия
                Оферты, предварительно согласовав с Заказчиком. Продолжая
                пользоваться Сайтом, Пользователь автоматически дает свое
                согласие на условия новой Оферты. Действующая редакция доступна
                по <a href="https://storage.yandexcloud.net/vector-public/offers/nlmk.docx">ссылке</a>.</span>                
            </p>
          </td>
        </tr>
        <tr>
          <td
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span style="font-weight: bold">10.7.</span>
            </p>
          </td>
          <td
            colspan="2"
            style="
              padding-top: 3.75pt;
              padding-right: 3.75pt;
              padding-bottom: 3.75pt;
              vertical-align: top;
            "
          >
            <p style="margin-bottom: 0pt; font-size: 12pt">
              <span
                >Во всем остальном, что не предусмотрено Офертой, стороны
                руководствуются законодательством Российской Федерации.</span
              >
            </p>
          </td>
        </tr>
      </table>
      <p><span style="-aw-import: ignore">&#xa0;</span></p>
    </div>
`;
function NlmkOfferBody() {
    return (
        <div dangerouslySetInnerHTML={{ __html: html }} />
    );
}

export default NlmkOfferBody;
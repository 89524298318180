import {RootStore} from "./RootStore";
import {makeAutoObservable, runInAction} from "mobx";
import {GiftCertificateModel} from "../types/GiftCertificateModel";
import {getGiftCertificates, getBanners, getGetRestGiftCertificatesNumbers} from "../services/CatalogService";
import {RequestState} from "../types/RequestState";
import {BannerModel} from "../types/BannerModel";
import {RestGiftCertificatesNumberDto} from "../types/RestGiftCertificatesNumberDto";
import {
  GiftCertificateFaceValueDto,
  GiftCertificateFaceValueGroupModel,
  VendorFaceValueGroupDto
} from "../types/FaceValueModel";

export class CatalogStore {
  private _rootStore: RootStore;
  tags: string[] = [];
  giftCertificates: GiftCertificateModel[] = [];
  selectedGiftCertificate: GiftCertificateModel | null = null;
  currentTag: string | null = null;
  getGiftCertificatesState: RequestState = RequestState.Init;
  banners: BannerModel[] = [];
  restGiftCertificatesNumbers: RestGiftCertificatesNumberDto[] = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this._rootStore = rootStore;
  }

  getBanners = () => {
    return getBanners()
      .then((banners) => {
        runInAction(() => {
          this.banners = banners;
        })
      })
      .catch(() => {
      });
  };

  getGetRestGiftCertificatesNumbers = (groups: GiftCertificateFaceValueGroupModel[]) => {
    return getGetRestGiftCertificatesNumbers({
      vendorFaceValueGroups: groups.map(x => ({id: x.vendorFaceValueGroup.id, faceValueIds: x.faceValues.map(x => x.faceValue.id)}))
    })
      .then((response) => {
        return runInAction(() => {
          this.restGiftCertificatesNumbers = response;
          return this.restGiftCertificatesNumbers;
        })
      });
  };


  selectGiftCertificate(id: number) {
    if (this.giftCertificates.length == 0) {
      let interval = setInterval(() => {
        if (this.giftCertificates.length != 0) {
          clearInterval(interval);
        }
        this.giftCertificates.forEach((x) => {
          if (x.id == id) {
            runInAction(() => {
              this.selectedGiftCertificate = x;
            })
          }
        });
      }, 200)
    } else {
      this.giftCertificates.forEach((x) => {
        if (x.id == id) {
          runInAction(() => {
            this.selectedGiftCertificate = x;
          })
        }
      });
    }

  }

  getGiftCertificates(search?: string, force?:boolean) {
    this.getGiftCertificatesState = RequestState.Pending;
    if (this.giftCertificates.length > 0 && !force)
      return Promise.resolve(this.giftCertificates);
    return getGiftCertificates(search)
      .then((giftCertificates) => {
        this.getGiftCertificatesState = RequestState.Done;
        this.giftCertificates = giftCertificates.filter(x => x.isVisible).sort((a, b) => {
          if (a.position > b.position) {
            return 1;
          }
          if (a.position < b.position) {
            return -1;
          }
          return 0;
        });
        this.giftCertificates.forEach((giftCertificate) => {
          giftCertificate.tags.forEach((tag) => {
            if (this.tags.findIndex((x) => x === tag.name) === -1) {
              runInAction(() => {
                this.tags.push(tag.name);
              });
            }
          });
          this.tags.sort();
        });
        return this.giftCertificates;
      })
      .catch((error) => {
        this.getGiftCertificatesState = RequestState.Error;
        throw error;
      });
  }

  setCurrentTag(tag: string | null) {
    this.currentTag = tag;
  }

  setCurrentSearchValue(value: string) {
      this.getGiftCertificates(value, true);
  }

  get filteredGiftCertificate(): GiftCertificateModel[] {
    if (!this.currentTag) return this.giftCertificates;

    return this.giftCertificates.filter((giftCertificate) => {
      let exists = false;
      giftCertificate.tags.forEach((tag) => {
        if (tag.name === this.currentTag) exists = true;
      });
      return exists;
    });
  }
}

import React, {Fragment, useEffect, useState} from "react";
import "./ProductDetailPage.css";
import {useStores} from "../store";
import {useHistory, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import FaceValueSelector from "../components/FaceValueSelector/FaceValueSelector";
import Button, {ButtonSize, ButtonStyle, ButtonType,} from "../components/Button/Button";
import {GiftCertificateFaceValueDto, VendorFaceValueGroupDto} from "../types/FaceValueModel";
import Point, {PointSize} from "../components/Point/Point";
import {CLIENT_ID} from "../constants";

const ProductDetailPage = () => {
  const {store} = useStores();
  const {id} = useParams<any>();

  const [isShowPreprodPanel, setIsShowPreprodPanel] = useState(false);

  const [faceValue, setFaceValue] = useState<GiftCertificateFaceValueDto>();
  const [selectedGroup, setSelectedGroup] = useState<VendorFaceValueGroupDto>();


  const history = useHistory();

  useEffect(() => {
    store.catalog.selectGiftCertificate(id);
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {

    setSelectedGroup(store.catalog.selectedGiftCertificate?.groups[0].vendorFaceValueGroup);
    setFaceValue(store.catalog.selectedGiftCertificate?.groups[0].faceValues[0]);
    if (store.catalog.selectedGiftCertificate) {
      store.catalog.getGetRestGiftCertificatesNumbers(store.catalog.selectedGiftCertificate.groups);
    }

  }, [store.catalog.selectedGiftCertificate]);

  const addToCart = () => {
    setIsShowPreprodPanel(true);
    if (selectedGroup && store.catalog.selectedGiftCertificate && faceValue) {
      store.cart.addCertificate(
        store.catalog.selectedGiftCertificate.id,
        store.catalog.selectedGiftCertificate.vendorName,
        store.catalog.selectedGiftCertificate.cardImage,
        faceValue.faceValue,
        faceValue.price,
        store.catalog.selectedGiftCertificate.vendorId,
        selectedGroup.id
      );
    }
  };

  return (
    <div>
      <div className={"product-detail-page"}>
        <div className={"product-detail-page__sidebar"}>
          <div
            className={"product-detail-page__stick"}
            style={{
              backgroundImage: `url(${store.catalog.selectedGiftCertificate?.backgroundImage})`,
              backgroundSize: store.catalog.selectedGiftCertificate?.backgroundImageSize || 'cover'
            }}
          >
            <div className={"product-detail-page__stick-content"}>
              <div className={"product-detail-page__logo"}>
                <img
                  src={store.catalog.selectedGiftCertificate?.logoImage}
                  alt={""}
                />
              </div>
              {store.catalog.selectedGiftCertificate &&
                faceValue &&
                store.catalog.selectedGiftCertificate.groups &&
                store.catalog.selectedGiftCertificate.groups.length > 0 &&
                !isShowPreprodPanel && (
                  <Fragment>
                    {store.catalog.selectedGiftCertificate.groups.map(fvg =>
                      <div className={"product-detail-page__face-value"}>

                        <FaceValueSelector
                          title={store.catalog.selectedGiftCertificate!.groups.length === 1 ? "Номинал" : fvg.name}
                          faceValues={fvg.faceValues}
                          onSelect={(faceValue) => {
                            setSelectedGroup(fvg.vendorFaceValueGroup);
                            setFaceValue(faceValue);
                          }}
                          selectedFaceValue={fvg.vendorFaceValueGroup.id == selectedGroup?.id ? faceValue : undefined}
                          restGiftCertificatesNumbers={store.catalog.restGiftCertificatesNumbers!.filter(x => x.vendorFaceValueGroupId == fvg.vendorFaceValueGroup.id)}
                        />
                      </div>)
                    }

                    <div className={"product-detail-page__button-cart"}>
                      <Button
                        style={ButtonStyle.Primary}
                        size={ButtonSize.Big}
                        type={[ButtonType.Block]}
                        onClick={addToCart}
                      >
                        Купить за&nbsp;<Point size={CLIENT_ID == "nlmk-catalog-webui" ? PointSize.Big : PointSize.Normal} amount={faceValue?.price}/>
                      </Button>
                    </div>
                  </Fragment>
                )}
              {isShowPreprodPanel && (
                <div className={"product-detail-page__preorder"}>
                  <div className={"product-detail-page__preorder-title"}>
                    Товар в корзине!
                  </div>
                  <div className={"product-detail-page__preorder-button"}>
                    <Button
                      style={ButtonStyle.White}
                      size={ButtonSize.Big}
                      type={[ButtonType.Block, ButtonType.Transparent]}
                      onClick={() => {
                        setIsShowPreprodPanel(false);
                      }}
                    >
                      Продолжить покупки
                    </Button>
                  </div>
                  <div className={"product-detail-page__preorder-button"}>
                    <Button
                      style={ButtonStyle.Primary}
                      size={ButtonSize.Big}
                      type={[ButtonType.Block]}
                      onClick={() => {
                        history.push("/cart");
                      }}
                    >
                      Оформить заказ
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: store.catalog.selectedGiftCertificate
              ?.description as string,
          }}
          className={"product-detail-page__description"}
        ></div>
      </div>
    </div>
  );
};

export default observer(ProductDetailPage);

import React, {useEffect, useState} from "react";
import "./IncreaseBalanceEvent.css";
import {IncreaseBalanceEventModel} from "../../types/IncreaseBalanceEventModel";
import image from "../../assets/images/csrds-group.svg"
import Point, {PointSize} from "../Point/Point";
import Moment from "moment";
import CurrentClientProfile from "../../client-profile";

interface IncreaseBalanceProps {
  increaseBalance: IncreaseBalanceEventModel;
}

const IncreaseBalanceEvent = ({increaseBalance}: IncreaseBalanceProps) => {

  return (
    <div className={"increase-balance-event"}>
      <div className="card-empty-image increase-balance-event__card-image">
        <div className="card-empty-image__point-circle">{CurrentClientProfile.coinName || 'B' }</div>
      </div>
      <div className="increase-balance-event__body">
          <span className="increase-balance-event__title">
            {increaseBalance.name}
          </span>
      </div>
      <div className="increase-balance-event__description">
          <div className={"increase-balance-event__total"}>
            <Point isTopUp amount={increaseBalance.amount} size={PointSize.Big} />
          </div>
          <div className={"increase-balance-event__date"}>{Moment(increaseBalance.createdDate).format('DD.MM.yyyy HH:mm')}</div>
        </div>
    </div>
  );
};

export default IncreaseBalanceEvent;

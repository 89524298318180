import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./EventsPage.css";
import Title from "../components/Title/Title";
import ButtonGroup from "../components/ButtonGroup/ButtonGroup";
import OrderEvent from "../components/OrderEvent/OrderEvent";
import {useStores} from "../store";
import {observer} from "mobx-react-lite";
import {EventType} from "../types/EventModel";
import IncreaseBalanceEvent from "../components/IncreaseBalanceEvent/IncreaseBalanceEvent";
import {saveAs} from 'file-saver';
import CurrentClientProfile from "../client-profile";
import Switch, {Case, Default} from "react-switch-case";
import {CLIENT_ID} from "../constants";

const EventsPage = () => {
    const [activeId, setActiveId] = useState("0");
    const {store} = useStores();

    useEffect(() => {
        store.participants.getProfile();
        store.participants.getEvent();
    }, []);

    let getGiftCertificate = useCallback((vendorId: number) => store.catalog.giftCertificates.find(x => x.vendorId == vendorId), [store.catalog]);

    const handleResendOrder = (id: number) => {
        store.orders.resend(id);
    }

    const handleDownloadOrder = (id: number) => {
        store.orders.get(id)
            .then(order => {
                order?.giftCertificateItems.forEach(item => {
                    store.orders.downloadItem(id, item.id)
                        .then(response => response.blob())
                        .then(file => {
                            saveAs(file, item.vendorName + "-" + item.faceValueName + ".pdf");
                        });
                })
            })
    }

    return (
        <div className={"events-page"}>
            <div className={"events-page__title"}>
                <Title as={"h1"}>
                    <Switch condition={CLIENT_ID}>
                        <Case value={"nlmk-catalog-webui"}>
                            Заказы
                        </Case>
                        <Default>
                            События
                        </Default>
                    </Switch>
                </Title>
            </div>
            <div className={"events-page__filter"}>
                {!CurrentClientProfile.disableTopUp && <ButtonGroup
                    onClick={(id) => {
                        setActiveId(id);
                    }}
                    buttons={[
                        {id: "0", name: "Все"},
                        {id: "1", name: "Заказы"},
                        {id: "2", name: "Пополнения"},
                    ]}
                    activeId={activeId}
                />}
            </div>
            <div className={"events-page__items"}>
                {store.participants.events?.map((event) => (
                    <div className={"events-page__item"}>
                        {event.type === EventType.Order && event.order !== null && (activeId === "0" || activeId === "1") &&
                        <OrderEvent
                            handleResend={handleResendOrder}
                            handleDownload={handleDownloadOrder}
                            email={store.participants.profile?.email!}
                            order={event.order}
                            cardImage={getGiftCertificate(event.order.giftCertificateItems[0].vendorId)?.cardImage}/>
                        }
                        {event.type === EventType.IncreaseBalance && event.increaseBalance !== null && (activeId === "0" || activeId === "2") &&
                        <IncreaseBalanceEvent increaseBalance={event.increaseBalance}/>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};

export default observer(EventsPage);

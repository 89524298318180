import React, {useEffect, useState} from "react";
import "./Header.css";
import Logo from "../Logo/Logo";
import NavBar from "../NavBar/NavBar";
import AddSaleButton from "../AddSaleButton/AddSaleButton";
import NavBarMobile from "../NavBarMobile/NavBarMobile";
import CartWidgetContainer from "../../containers/CartWidgetContainer";
import UserBarContainer from "../../containers/UserBarContainer";
import CurrentClientProfile from "../../client-profile";
import {NavLink, useHistory} from "react-router-dom";
import SearchInput from "../SearchInput/SearchInput";
import {useQueryState} from "../../hooks/useQueryState";
import {getGiftCertificates} from "../../services/CatalogService";

const Header = () => {

    const [searchValue, setSearchValue] = useQueryState("q");
    const [s, setS] = useState(searchValue);
    const history = useHistory();
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(s);


    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(s);
        }, 200); // задержка 500 мс

        // Очистка таймера перед установкой нового
        return () => {
            clearTimeout(handler);
        };
    }, [s]);

    //
    // React.useEffect(() => {
    //     const getData = setTimeout(() => {
    //         setSearchValue(s);
    //         // getGiftCertificates(s)
    //         //     .then((response) => {
    //         //         console.log(response);
    //         //         setSearchValue(s);
    //         //     });
    //     }, 1000)
    //
    //     return () => clearTimeout(getData)
    // }, [s])

    const onSearch = () => {
        setSearchValue(s);
        history.push("/products" + (s ? `?q=${s}` : ""));
    }

    const onSearchTextChanged = (value: string) => {
        setS(value);
    }

    useEffect(() => {
        if (debouncedSearchTerm != undefined) {
            history.push(`/products?q=${debouncedSearchTerm}`);
        }
    }, [debouncedSearchTerm]);


    return (
        <div className={"header"}
             style={{height: CurrentClientProfile.header?.height ? CurrentClientProfile.header.height : "auto"}}>
            <div className={"header__item"}>
                <NavLink to={"/"}>
                    <Logo logo={CurrentClientProfile.logo}/>
                </NavLink>
            </div>
            <div className={"header__item header__item--mob-hide"}>
                <NavBar/>
            </div>

            <div className={"header__item header__item--separator"}/>
            <div className={"header__item"}>
                <div  className={"header__search"}>
                    <SearchInput id={'search'} value={s} onChange={onSearchTextChanged}
                                 placeholder={'Поиск'} onSearch={onSearch}/>
                </div>
            </div>
            <div className={"header__item header__item--mob-hide"}>
                <AddSaleButton/>
            </div>
            <div className={"header__item"}>
                <CartWidgetContainer/>
            </div>
            <div className={"header__item"}>
                <UserBarContainer/>
            </div>
            <div className={"header__item header__item--mob-show"}>
                <NavBarMobile/>
            </div>
        </div>
    );
};

export default Header;

import moment from "moment";
import {Moment} from "moment/moment";

export interface GiftCertificateFaceValueDto {
    id: number;
    faceValue: FaceValueDto;
    price: number;
}

export interface FaceValueDto {
    id: number;
    value: number;
    unit: FaceValueUnit;
}

export const formatFaceValue = (faceValue: FaceValueDto) => {
    return `${faceValue.value} ${FaceValueUnitsName[faceValue.unit]}`;
}

export function defaultDayFormatDate(value: Moment) {
    if(!value)
        return "";

    return value.format('DD.MM.yyyy г.');
}
export enum FaceValueUnit {
    Ruble = 1,
    Month = 2
}

export const FaceValueUnitsName : Record<FaceValueUnit, string> = {
    [FaceValueUnit.Ruble]: 'руб.',
    [FaceValueUnit.Month]: 'мес.'

};

export interface GiftCertificateFaceValueGroupModel {
    id: number;
    name: string;
    faceValues: GiftCertificateFaceValueDto[]
    vendorFaceValueGroup: VendorFaceValueGroupDto
}

export interface VendorFaceValueGroupDto {
    id: number;
    name: string;
}
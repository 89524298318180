import React, {useEffect} from "react";
import CartStickPanel from "../components/CartStickPanel/CartStickPanel";
import {useStores} from "../store";
import {observer} from "mobx-react-lite";
import CurrentClientProfile from "../client-profile";

interface CartStickPanelContainerProps {
  onCreateOrder: () => void;
}
const WITHDRAWAL_VENDOR_ID = 142;
const CartStickPanelContainer = (props: CartStickPanelContainerProps) => {
  const { store } = useStores();
  let errorMessage = null;

  if(store.participants.profile?.email == '' || !store.participants.profile?.emailConfirmed){
    errorMessage = "Почта не подтверждена";
  } else if (CurrentClientProfile.userProfile.ndfl) {
    if(store.participants.profile?.personalIncomeTaxData == null)
      errorMessage = "Не указаны данные НДФЛ";
    else if(store.participants.profile?.personalIncomeTaxData.passportAddress == null)
      errorMessage = "Не указан Адрес регистрации. Пожалуйста, обратитесь в службу поддержки";
  }

  if(store.participants.profile?.cardNumber == null && store.cart.items.some(x => x.vendorId == WITHDRAWAL_VENDOR_ID))
  {
      errorMessage = 'Не указан Номер карты. Пожалуйста, заполните его на странице "Настройка профиля" или обратитесь в службу поддержки';
  }

  useEffect(() => {

  }, []);

  return (
    <div>
      {store.cart.total === 0 ? (
        <div>

        </div>
      ) : (
        <div>
          <CartStickPanel
            amount={store.participants.balance}
            total={store.cart.total}
            onCreateOrder={props.onCreateOrder}
            enableOrder={(errorMessage === null)}
            errorMessage={errorMessage}
          />
        </div>
      )}
    </div>
  );
};

export default observer(CartStickPanelContainer);

import React from 'react';
import './AccordionTitle.css';
import Title from "../Title/Title";

function AccordionTitle(props: any) {
    return (
        <Title as={"h4"}>{props.children}</Title>
    );
}

export default AccordionTitle;
import React from 'react';
import Alert, {AlertType} from "../Alert/Alert";
import Title from "../Title/Title";
import Paragraph from "../Paragraph/Paragraph";
import {CLIENT_ID} from "../../constants";

const AlertNdfl = () => {
    return (
        <Alert type={AlertType.Warning}>
            <Title as={"h4"} m={8}>
                {CLIENT_ID == 24 ? "Пройдите идентификацию, чтобы оформить заказ." : "Заполните данные для оплаты НДФЛ, чтобы получить возможность оформить заказ."}
            </Title>
            {CLIENT_ID == 24 && <>
              <Paragraph>
                Вам требуется заполнить анкету и прикрепить документы, необходимые для прохождения идентификации в
                соответствии с Федеральным законом №115-ФЗ.
              </Paragraph>
              <Paragraph>
                Все персональные данные хранятся и обрабатываются в соответствии с Федеральным законом №152-ФЗ.
              </Paragraph>
            </>}
            {CLIENT_ID != 24 && <>
              <Paragraph>
                С вознаграждения, полученного в рамках мотивационной программы, требуется оплатить НДФЛ по ставке 13%.
                Организатор программы выступает налоговым агентом и самостоятельно перечисляет сумму НДФЛ в бюджет.
              </Paragraph>
              <Paragraph>
                Для упрощения получения приза, налог уплачивается организатором
                данной программы, который выступает Вашим налоговым агентом.
                Заполнение анкеты нужно для того, чтобы организатор имел
                возможность оплатить налог без потери времени для Вас.
              </Paragraph>
              <Paragraph>
                Вам требуется заполнить анкету и прикрепить документы, необходимые для заполнения формы 6-НДФЛ.
                Все персональные данные хранятся и обрабатываются в соответствии с Федеральным законом N 152-ФЗ.
              </Paragraph>
            </>}
        </Alert>
    );
};

export default AlertNdfl;

import React from 'react';
import './AccordionContent.css';

function AccordionContent(props: any) {
    return (
        <div>
            {props.children}
        </div>
    );
}

export default AccordionContent;
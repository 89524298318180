import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "swiper/swiper-bundle.css";

// Import Swiper and modules
import SwiperCore, {Autoplay, EffectFade, Mousewheel, Navigation, Pagination, Scrollbar,} from "swiper/core";
import moment from "moment";
import {CLIENT_ID} from "./constants";
import "./styles/default.css";


moment.locale('ru');

// Install modules
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
  Mousewheel,
  EffectFade,
]);
import(`./styles/${CLIENT_ID}.css`);

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))s
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

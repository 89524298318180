import React from 'react';
import "./BackArrow.css"
import Arrow, {ArrowType} from "../Arrow/Arrow";
import Link from "../Link/Link";

export interface BackArrowProps {
    backUrl?: string
}

function BackArrow(props: BackArrowProps) {
    return (
        <> {
            props.backUrl && <Link to={props.backUrl}>
                <div className={"back-arrow-wrapper"}>

                    <Arrow type={ArrowType.Left} size={6}/>
                    <span className={"back-arrow-text"}>Вернуться на портал</span>

                </div>
            </Link>
        }
        </>
    );
}

export default BackArrow;
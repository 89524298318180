import React from "react";
import "./ThankForOrderPage.css";
import Button, {ButtonSize, ButtonStyle} from "../components/Button/Button";
import {useHistory} from "react-router-dom";
import {CLIENT_ID} from "../constants";
import Switch, {Case, Default} from 'react-switch-case';

const ThankForOrderPage = () => {
    const history = useHistory();
    return (
        <div className={"thank-for-order-page"}>
            <div className={"thank-for-order-page__center"}>
                <div className={"thank-for-order-page__icon"}></div>
                <div className={"thank-for-order-page__title"}>Заказ принят</div>
                <div className={"thank-for-order-page__text"}>
                    <Switch condition={CLIENT_ID}>
                        <Case value="raiffeisen-catalog-webui">
                            <span>Письмо с Вашим заказом придет на почту, которую Вы указали при оформлении. Следите за статусом заказа и не забывайте проверять папку спам.</span>
                        </Case>
                        <Case value="nlmk-catalog-webui">
                            <span>Сертификат поступит на Вашу электронную почту. Дополнительно Вы сможете скачать его в разделе "Заказы" в Личном кабинете.</span>
                        </Case>
                        <Default>
                            <span>Если выбранный Вами сертификат отсутствует на складе, срок доставки Вашего заказа будет составлять от 3 до 5 рабочих дней, не считая дня заказа</span>
                        </Default>
                    </Switch>

                </div>
                <div className={"thank-for-order-page__button"}>
                    <Button
                        onClick={() => {
                            history.push("/events");
                        }}
                        size={ButtonSize.Big}
                        style={ButtonStyle.Primary}
                    >
                        К списку покупок
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ThankForOrderPage;

import React, {useEffect, useState} from "react";
import FormSection from "../Form/FormSection";
import FormRow from "../Form/FormRow";
import TextInput from "../TextInput/TextInput";
import UploadDropzone from "../UploadDropzone/UploadDropzone";
import Form from "../Form/Form";
import Button, {ButtonSize, ButtonStyle} from "../Button/Button";
import {AddIncomeTaxDataRequestModel} from "../../types/AddIncomeTaxDataRequestModel";
import {PersonalIncomeTaxData} from "../../types/PersonalIncomeTaxData";
import moment from "moment";

interface IncomeTaxDataFormProps {
  fio: string;
  onSubmit: (request: AddIncomeTaxDataRequestModel) => void;
  personalIncomeTaxData: PersonalIncomeTaxData | null;
  pending: boolean;
}

const IncomeTaxDataForm = (props: IncomeTaxDataFormProps) => {
  const [passportNumber, setPassportNumber] = useState<string>("");
  const [passportSeries, setPassportSeries] = useState<string>("");
  const [passportIssuedBy, setPassportIssuedBy] = useState<string>("");
  const [passportIssueDate, setPassportIssueDate] = useState<string>("");
  const [passportDepartmentCode, setPassportDepartmentCode] = useState<string>(
    ""
  );
  const [passportAddress, setPassportAddress] = useState<string>("");
  const [passportAddressRegion, setPassportAddressRegion] = useState<string>("");
  const [passportAddressCity, setPassportAddressCity] = useState<string>("");
  const [passportAddressStreet, setPassportAddressStreet] = useState<string>("");
  const [passportAddressBuilding, setPassportAddressBuilding] = useState<string>("");
  const [passportAddressAptNo, setPassportAddressAptNo] = useState<string>("");

  const [scanPassportFiles, setScanPassportFiles] = useState<File[]>([]);

  const [fio, setFio] = useState<string>("");
  const [inn, setInn] = useState<string>("");
  const [birthday, setBirthday] = useState<string>("");

  const [editable, setEditable] = useState<boolean>(false);

  useEffect(() => {
    if (!props.personalIncomeTaxData) {
      setFio(props.fio);
      setInn("");
      setBirthday("");
      setPassportDepartmentCode("");
      setPassportIssueDate("");
      setPassportIssuedBy("");
      setPassportSeries("");
      setPassportNumber("");
      setPassportAddress("");
      setPassportAddressRegion("");
      setPassportAddressCity("");
      setPassportAddressStreet("");
      setPassportAddressBuilding("");
      setPassportAddressAptNo("");
      setEditable(true);
    } else {
      setFio(props.personalIncomeTaxData.fio);
      setInn(props.personalIncomeTaxData.inn);
      setBirthday(props.personalIncomeTaxData.birthday);
      setPassportDepartmentCode(props.personalIncomeTaxData.passportDepartmentCode);
      setPassportIssueDate(props.personalIncomeTaxData.passportIssueDate);
      setPassportIssuedBy(props.personalIncomeTaxData.passportIssuedBy);
      setPassportSeries(props.personalIncomeTaxData.passportSeries);
      setPassportNumber(props.personalIncomeTaxData.passportNumber);
      setPassportAddress(props.personalIncomeTaxData.passportAddress);

      setEditable(false);
    }
  }, [props.personalIncomeTaxData]);

  const onSubmit = () => {

    props.onSubmit({
      fio,
      inn,
      birthday: moment(birthday+" 00:00",'DD.MM.YYYY hh:mm').utc(true).unix(),
      passportNumber,
      passportSeries,
      passportIssueDate: moment(passportIssueDate+" 00:00",'DD.MM.YYYY hh:mm').utc(true).unix(),
      passportDepartmentCode,
      passportIssuedBy,
      scanPassportFiles,
      passportAddressRegion,
      passportAddressCity,
      passportAddressStreet,
      passportAddressBuilding,
      passportAddressAptNo
    });
  };

  return (
    <Form title={"Данные для НДФЛ"}>
      <FormRow>
        <TextInput
          label={"Фамилия Имя Отчество"}
          id={"fio"}
          onChange={setFio}
          value={fio}
          editable={editable}
        />
      </FormRow>
      <FormRow>
        <TextInput
          label={"Дата рождения"}
          id={"birthday"}
          onChange={(text) => {
            setBirthday(text);
          }}
          value={birthday}
          editable={editable}
          mask={[/[0-3]/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
        />
        <TextInput
          label={"ИНН"}
          id={"inn"}
          onChange={setInn}
          value={inn}
          editable={editable}
        />
      </FormRow>

      <FormSection title={"Паспортные данные"}>
        <FormRow>
          <TextInput
              label={"Серия"}
              id={"passportSeries"}
              onChange={setPassportSeries}
              value={passportSeries}
              editable={editable}
              mask={[/\d/, /\d/, /\d/, /\d/]}
          />
          <TextInput
            label={"Номер"}
            id={"passportNumber"}
            onChange={setPassportNumber}
            value={passportNumber}
            editable={editable}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
          />
        </FormRow>
        <FormRow>
          <TextInput
            label={"Кем выдан"}
            id={"firstname"}
            onChange={setPassportIssuedBy}
            value={passportIssuedBy}
            editable={editable}
          />
        </FormRow>
        <FormRow>
          <TextInput
            label={"Дата выдачи"}
            id={"passportIssueDate"}
            onChange={setPassportIssueDate}
            value={passportIssueDate}
            editable={editable}
            mask={[/[0-3]/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/]}
          />
          <TextInput
            label={"Код подразделения"}
            id={"passportDepartmentCode"}
            onChange={setPassportDepartmentCode}
            value={passportDepartmentCode}
            editable={editable}
            mask={[/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
          />
        </FormRow>
        {!editable && <FormRow>
          <TextInput
              label={"Адрес регистрации"}
              id={"passportAddress"}
              onChange={setPassportAddress}
              value={passportAddress}
              editable={editable}
          />
        </FormRow>}
        </FormSection>
        {editable && <FormSection title={"Адрес регистрации"}>
        <FormRow>
          <TextInput
              label={"Регион"}
              id={"passportAddressRegion"}
              onChange={setPassportAddressRegion}
              value={passportAddressRegion}
              editable={editable}
          />
          <TextInput
              label={"Город"}
              id={"passportAddressCity"}
              onChange={setPassportAddressCity}
              value={passportAddressCity}
              editable={editable}
          />
        </FormRow>
        <FormRow>
          <TextInput
              label={"Улица"}
              id={"passportAddressStreet"}
              onChange={setPassportAddressStreet}
              value={passportAddressStreet}
              editable={editable}
          />
        </FormRow>
        <FormRow>
          <TextInput
              label={"Дом"}
              id={"passportAddressBuilding"}
              onChange={setPassportAddressBuilding}
              value={passportAddressBuilding}
              editable={editable}
          />
          <TextInput
              label={"Квартира"}
              id={"passportAddressAptNo"}
              onChange={setPassportAddressAptNo}
              value={passportAddressAptNo}
              editable={editable}
          />
        </FormRow>
        </FormSection>}

      {!props.personalIncomeTaxData && (
        <FormSection title={"Скан паспорта (разворот с фотографией и страница с пропиской)"}>
          <FormRow>
            <UploadDropzone onDrop={setScanPassportFiles} />
          </FormRow>
        </FormSection>
      )}

      {!props.personalIncomeTaxData && (
        <FormRow>
          <Button
            onClick={onSubmit}
            size={ButtonSize.Big}
            style={ButtonStyle.Primary}
            pending={props.pending}
          >
            Сохранить
          </Button>
        </FormRow>
      )}
    </Form>
  );
};

export default IncomeTaxDataForm;

import React from "react";
import UserBar from "../components/UserBar/UserBar";
import {useStores} from "../store";
import {observer} from "mobx-react-lite";
import ClientProfile from "../client-profile";

const UserBarContainer = () => {
    const {store} = useStores();
    return <UserBar
        name={ClientProfile.fioAsLogin ? `${store.participants.profile?.firstname} ${store.participants.profile?.lastname} ${store.participants.profile?.surname}`.trim() : store.participants.profile?.userName}
        balance={store.participants.balance}/>;
};

export default observer(UserBarContainer);

import React, {useEffect, useState} from 'react';
import NlmkModalModalWindow from "../ModalWindows/NlmkOfferModalWindow";
import {useStores} from "../../store";
import {CLIENT_ID} from "../../constants";

export interface AcceptOfferProps {
    alwaysOpen: boolean;
    refresh?: number;
}
function AcceptOffer(props: AcceptOfferProps) {
    let {store} = useStores();
    const [showModalWindow, setShowModalWindow] = useState(false);

    useEffect(() => {
        if(CLIENT_ID == 'nlmk-catalog-webui' && (sessionStorage.getItem('acceptOffer') == null || props.alwaysOpen) && store.participants?.profile != null){
            sessionStorage.setItem('acceptOffer', store.participants.profile.acceptOffer?.toString() || '');
            if(store.participants.profile.acceptOffer != 'true') {
                setShowModalWindow(true);
            }
        }
    }, [store.participants?.profile, props.refresh]);


    const onCloseNlmWindow = () => {
        setShowModalWindow(false);
    };

    const onAcceptNlmkOffer = () => {
        store.participants.acceptOffer()
            .then(() => {
                setShowModalWindow(false);
                store.participants.acceptOffer();
            });
    }

    return (
        <div>
            {showModalWindow && (
                <NlmkModalModalWindow
                    onClose={onCloseNlmWindow}
                    onAccept={onAcceptNlmkOffer}
                    acceptOfferRequestState={store.participants.acceptRuleState}
                />
            )}
        </div>
    );
}

export default AcceptOffer;
import React from "react";
import Button, {ButtonSize, ButtonStyle, ButtonType} from "../Button/Button";
import Point, {PointSize} from "../Point/Point";
import "./CartStickPanel.css";
import classNames from "classnames";
import { useHistory} from "react-router-dom";
import ConfirmEmailBlock from "../ConfirmEmailBlock/ConfirmEmailBlock";
import CurrentClientProfile from "../../client-profile";

interface CartStickPanelProps {
  amount: number;
  total: number;
  onCreateOrder: () => void;
  enableOrder: boolean;
  errorMessage: string | null;
}

const CartStickPanel = (props: CartStickPanelProps) => {
  let history = useHistory();
  return (
    <div className={"cart-stick-panel"}>
      <div className={"cart-stick-panel__title"}>Заказ на сумму</div>
      <div
        className={classNames({
          "cart-stick-panel__amount": true,
          "cart-stick-panel__amount--error": props.amount < props.total,
        })}
      >
        <Point size={PointSize.Big} amount={props.total} />
      </div>


      {props.enableOrder && props.amount < props.total && (
        <div className={"cart-stick-panel__text"}>
          Удалите товаров на сумму <b>{props.total - props.amount} {CurrentClientProfile.coinName || 'B' }</b>
        </div>
      )}
      {!props.enableOrder && (
        <div className={"cart-stick-panel__text"}>
          {props.errorMessage}
        </div>
      )}
      <div className={"cart-stick-panel__button"}>
      <Button
              onClick={props.onCreateOrder}
              size={ButtonSize.Big}
              style={ButtonStyle.Primary}
              type={[ButtonType.Block]}
              disabled={props.amount < props.total || !props.enableOrder}
          >
              Заказать
          </Button>
      </div>
    </div>
  );
};

export default CartStickPanel;
